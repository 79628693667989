import React, { Children } from "react"
import Link from "gatsby-link"

const FooterSocialMedia = (props) => {
    return (
    <div className={((props && props.className) || "") + " social-media-links"} >
    <span className="social-media-labels">Go Social</span>
    <ul className="social-media-handles">
      <li>
        <a href="https://www.facebook.com/muvit.ng/">
          <svg width="20px" height="20px">
            <use xlinkHref="#facebook" />
          </svg>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/MuvitOnline">
          <svg width="20px" height="20px">
            <use xlinkHref="#twitter" />
          </svg>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/muvit.com.ng/?hl=en">
          <svg width="20px" height="20px">
            <use xlinkHref="#instagram" />
          </svg>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/muvit-online-limited-284584188/">
          <svg width="20px" height="20px">
            <use xlinkHref="#linkedin" />
          </svg>
        </a>
      </li>
    </ul>
  </div>
)
}

export default FooterSocialMedia
