import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/muvit_logo.svg"
// import redirectToPage from "./gatsby/node"

const active_menu = "item-active"

const gotoPartnerPage = event => (window.location.href = "/business")

const Header = ({ siteTitle }) => {
  return (
    <header>
      <div className="logo">
        <img src={Logo} />
      </div>
      <input type="checkbox" className="openNavMenu" id="openNavMenu" />
      <label htmlFor="openNavMenu" className="navMenuIconToggle">
        <div className="spinner diagonal part-1"></div>
        <div className="spinner horizontal"></div>
        <div className="spinner diagonal part-2"></div>
      </label>
      <nav className="nav--menu">
        <ul className="menu--list">
          <li className="menu--item">
            <Link to="/" activeClassName={active_menu}>
              home
            </Link>
          </li>
          <li className="menu--item">
            <Link to="/get-estimate" activeClassName={active_menu}>
              estimate delivery
            </Link>
          </li>
          <li className="menu--item">
            <Link to="/business" activeClassName={active_menu}>
              business
            </Link>
          </li>
          <li className="menu--item">
            <Link to="/about" activeClassName={active_menu}>
              about
            </Link>
          </li>
          <li className="menu--item">
            <Link to="/faq" activeClassName={active_menu}>
              faq
            </Link>
          </li>
          <li className="menu--item">
            <Link to="/contact" activeClassName={active_menu}>
              support
            </Link>
          </li>
        </ul>
        <div className="header--action--buttons">
          <button
            className="cta cta--partner--registration"
            onClick={gotoPartnerPage}
          >
            <div className="header--icon">
              <svg width="30px" height="30px">
                <use xlinkHref="#time" />
              </svg>
            </div>
            <div className="cta--partner--registration--text">
              <span className="text-uppercase block-item">Drive with us</span>
              <span className="cta--partner--registration--text--sub">
                turn KM into money
              </span>
            </div>
            <div className="cta--partner--registration--text--2">JOIN US</div>
          </button>
        </div>
      </nav>
      <div className="header--action--buttons">
        <button
          className="cta cta--partner--registration"
          onClick={gotoPartnerPage}
        >
          <div className="header--icon">
            <svg width="30px" height="30px">
              <use xlinkHref="#time" />
            </svg>
          </div>
          <div className="cta--partner--registration--text">
            <span className="text-uppercase block-item">Drive with us</span>
            <span className="cta--partner--registration--text--sub">
              turn KM into money
            </span>
          </div>
          <div className="cta--partner--registration--text--2">JOIN US</div>
        </button>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
