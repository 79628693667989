import React from "react"
import { Link } from "gatsby"

import DownloadAppButton from "../components/download-app-buttons"
import FooterSocialMedia from "../components/footer-social-media"

const Footer = () => (
  <footer>
    <section className="section--content">
      <div className="footer-menu flex-1">
        <div className="footer-menu-item">
          <div className="menu-item-header">
            <h2>company</h2>
          </div>
          <ul className="menu-group">
            <li className="menu-item">
              <Link to="/about">about</Link>
            </li>
            <li className="menu-item">
              <Link to="/contact">contact</Link>
            </li>
            <li className="menu-item">
              <Link to="#">careers</Link>
            </li>
          </ul>
        </div>
        <div className="footer-menu-item">
          <div className="menu-item-header">
            <h2>Services and Pricing</h2>
          </div>
          <ul className="menu-group">
            <li className="menu-item">
              <Link to="/business">business</Link>
            </li>
            <li className="menu-item">
              <Link to="#">Get Quote</Link>
            </li>
          </ul>
        </div>
        <div className="footer-menu-item">
          <div className="menu-item-header">
            <h2>support</h2>
          </div>
          <ul className="menu-group">
            <li className="menu-item">
              <Link to="/faq">FAQ</Link>
            </li>
            <li className="menu-item">
              <Link to="/terms">Terms and Condition</Link>
            </li>
            <li className="menu-item">
              <Link to="/policy">Policy</Link>
            </li>
          </ul>
        </div>
        <div className="footer-menu-item">
          <div className="menu-item-header">
            <h2>customer hotline</h2>
          </div>
          <div className="customer-care-hotline">
            014546880 <br />
            Monday - Saturday <br />
            09:00 - 18:00
          </div>
          <FooterSocialMedia />
        </div>
      </div>
      <FooterSocialMedia className="social-media-links-2" />
      <DownloadAppButton />
    </section>
    <div className="copyright-section">
      <p>
        © {new Date().getFullYear()} <strong>Muvit</strong>. All rights
        reserved.
      </p>
    </div>
  </footer>
)

export default Footer
