import React, { Children } from "react"
import PlayStoreImage from "../images/app-store-icons/dl_playstore@2x.png"
import AppStoreImage from "../images/app-store-icons/dl_appstore@2x.png"

const DownloadAppButton = ({ children }) => (
  <div className="download--app-panel">
    {children}
    <div className="download-app-section">
      <div className="download-app-section-content">
        <a
          href="https://play.google.com/store/apps/details?id=com.hts.muvitcustomer"
          className="app-store-icon"
          data-app-store="google-play"
        >
          <img src={PlayStoreImage} />
        </a>
      </div>
      <div className="download-app-section-content">
        <a href="#" className="app-store-icon" data-app-store="play-store">
          <img src={AppStoreImage} />
        </a>
      </div>
    </div>
  </div>
)

export default DownloadAppButton
